import { KoreanContainer, KoreanItem } from '../../styles/onBoardStyle';
import Checkbox from './checkbox';

interface UsPPProps {
	isFirstChecked: boolean;
	checkFirst: Function;
	isScndChecked: boolean;
	checkScnd: Function;
}

const UsPP = (props: UsPPProps) => {
	return (
		<KoreanContainer style={{ padding: '0px 0px 24px' }}>
			<KoreanItem style={{ marginLeft: 'initial' }}>
				<label>
					When you use the Clinique Clinical Reality Skincare Recommendation Diagnostic Tool,
					Clinique accesses and temporarily retains a scan of your facial geometry from your
					image to provide you with a skin diagnostic experience and related product
					recommendations. Your facial geometry data may be shared with our service providers
					for these purposes. Clinique will retain your facial geometry data for the duration
					of your use of the Clinique Clinical Reality Skincare Recommendation Diagnostic
					Tool and will not retain the data thereafter.
					The skin diagnostic results of the Clinique Clinical Reality Skincare
					Recommendation Diagnostic Tool may be characterized as sensitive personal
					information (“SPI”) and/or consumer health data (“CHD”) under applicable laws.
					For information about Clinique's privacy practice, see our {' '}
					<a style={{ textDecoration: 'underline' }} target={'_blank'} rel='noreferrer' href='https://www.clinique.com/customer-care/general-info/privacy-policy'>
						Privacy Policy
					</a> and our {' '}

					<a style={{ textDecoration: 'underline' }} target={'_blank'} rel='noreferrer' href='https://www.clinique.com/consumer-health-data-privacy-statement'>
						Consumer Health Data Privacy Statement
					</a>.
				</label>
			</KoreanItem>

			<KoreanItem>
				<Checkbox checked={props.isFirstChecked} onChange={() => props.checkFirst(!props.isFirstChecked)} disabled={false} />
				<label>You expressly consent and provide a written release
					to the temporary access, use, storage, and/or disclosure
					of your facial geometry data for the purposes described
					above. You also agree to be bound to all of the Terms and Conditions
					of the Clinique website in using the Clinique Clinical Reality Skincare
					Recommendation Diagnostic Tool. To review the entirety of the Terms
					and Conditions, please click {' '}

					<a style={{ textDecoration: 'underline' }} target={'_blank'} rel='noreferrer' href='https://www.clinique.com/customer-care/general-info/terms-conditions'>
						here
					</a>.
				</label>
			</KoreanItem>

			<KoreanItem>
				<Checkbox checked={props.isScndChecked} onChange={() => props.checkScnd(!props.isScndChecked)} disabled={false} />
				<label>
					By checking this box, you agree to the processing of your skin diagnostic results by Clinique, which may be characterized as SPI and/or CHD. This information is processed to provide you product recommendations. Subject to applicable law, you have the right to (1) withdraw your consent to the processing of SPI at any time by selecting “Limit Use of Sensitive Personal Information” on our <a style={{ textDecoration: 'underline' }} target={'_blank'} rel='noreferrer' href='http://bit.ly/465jWap'>
						Privacy Portal
					</a> and/or (2) request that Clinique cease collecting your CHD by visiting our {' '}
					<a style={{ textDecoration: 'underline' }} target={'_blank'} rel='noreferrer' href='http://bit.ly/465jWap'>
						Privacy Portal
					</a>.
				</label>
			</KoreanItem>
		</KoreanContainer>
	);
};

export default UsPP;