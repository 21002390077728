import styled from 'styled-components';
import cliniqueLogoW from '../assets/icons/CliniqueLogoW.png';
import cliniqueLogoB from '../assets/icons/CliniqueLogoB.png';

import cross from '../assets/icons/Cross.svg';
import { CliniqueState, Retailer, StepView } from '../type';
import { getCliniqueWebsiteForImageUrl, sendAnalytics } from '../constantes/utilitaries';
import { useSelector } from 'react-redux';

export const Topper = (props: { step: StepView; onClose?: Function; inSPP?: boolean }) => {
	const { sessionID, isMicrosite, market, retailer } = useSelector((state: CliniqueState) => state.session);

	const isBlackColored = () => {
		switch (props.step) {
			case StepView.quests:
			case StepView.onBoard:
			case StepView.results:
			case StepView.ready:
				return true;
			default:
				return false;
		}
	};

	const onClose = () => {
		if (props.onClose) {
			return props.onClose();
		} else {
			switch (props.step) {
				case StepView.quests:
					sendAnalytics('questions', 'exit tool', sessionID);
					break;
				case StepView.camera:
					sendAnalytics('photo upload or take', 'exit', sessionID);
					break;
				case StepView.pictureAnalyse:
					sendAnalytics('take photo validation', 'exit', sessionID);
					break;
				case StepView.results:
					sendAnalytics('concern recognition result', 'exit', sessionID);
					break;
				default:
					break;
			}

			if (isMicrosite || window.location.href.includes('qa-ccr')) {
				window.top!.location.href = document.referrer;
			} else {
				if (retailer === Retailer.sephora) {
					window.top!.location.href = 'https://www.sephora.fr';
				} else if (retailer === Retailer.MS) {
					window.top!.location.href = 'https://www.marksandspencer.com/c/beauty/clinique-clinical-reality';
				} else {
					window.top!.location.href = getCliniqueWebsiteForImageUrl(market) + '/clinicalreality';
				}

				//window.top!.location.href = document.referrer + "/clinicalreality"
			}
		}
	};

	return (
		<TopperContainer inSPP={props.inSPP} step={props.step}>
			<div />
			<CliniqueImg isBlack={isBlackColored()} src={isBlackColored() ? cliniqueLogoB : cliniqueLogoW} />
			<CrossImage onClick={() => onClose()} isBlack={isBlackColored()} src={cross} />
		</TopperContainer>
	);
};

const isDark = (step: StepView) => {
	switch (step) {
		case StepView.quests:
			return true;
		default:
			return false;
	}
};

export const TopperContainer = styled.div<{ step: StepView; inSPP?: boolean }>`
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: ${(props) => (props.step === StepView.results ? 'fixed' : 'absolute')};
	background-color: ${(props) => (props.step === StepView.results ? 'white' : '')};
	top: 0px;
	width: 100%;
	box-sizing: border-box;
	padding: 24px;
	left: 0px;
	z-index: 5;
	filter: ${(props) => (isDark(props.step) ? 'brightness(0)' : '')};

	@media (max-width: 768px) {
		position: ${(props) => (props.step === StepView.results ? 'relative' : 'absolute')};
		padding: ${(props) => (props.inSPP ? '24px 0px' : '16px 24px')};
	}
`;

const CliniqueImg = styled.img<{ isBlack: boolean }>`
	width: 190px;
	transition: all 0.5s;
`;

const CrossImage = styled.img<{ isBlack: boolean }>`
	filter: ${(props) => (props.isBlack ? 'brightness(0)' : '')};
	width: 0.9em;
	object-fit: none;
`;
