/**
 * Universal frame grabbing function that uses canvas-based capture
 * This version skips the ImageCapture API attempt entirely
 */
export const grabFrameUniversal = async (
    videoElement: HTMLVideoElement,
    track: MediaStreamTrack,
    options: FrameGrabOptions = {}
): Promise<FrameGrabResult> => {
    // Simply forward to the captureVideoFrame method
    return captureVideoFrame(videoElement, options);
};

/**
 * Capture a frame from a video element using canvas
 */
export const captureVideoFrame = (
    videoElement: HTMLVideoElement,
    options: FrameGrabOptions
): FrameGrabResult => {
    const { width, height, mirrored } = options;
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    canvas.width = width || videoElement.videoWidth;
    canvas.height = height || videoElement.videoHeight;

    if (mirrored && ctx) {
        ctx.translate(canvas.width, 0);
        ctx.scale(-1, 1);
    }

    ctx?.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

    return {
        canvas,
        dataUrl: canvas.toDataURL('image/jpeg')
    };
};

// Types still needed for TypeScript
export interface FrameGrabOptions {
    width?: number;
    height?: number;
    mirrored?: boolean;
}

export interface FrameGrabResult {
    canvas: HTMLCanvasElement;
    dataUrl: string;
}